body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.background {
  background-color: #282c34;
  justify-content: center;
}

.contact {
  max-width: 650px;
  min-width: 350px;
}

.header {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.center {
  text-align: center;
}
.center-div {
  margin: 0 auto;
}
